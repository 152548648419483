.parent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .card {
    margin: 5%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 20%;
  }
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  .container {
    padding: 2px 16px;
  }
  
  .head {
    width: 100%;
    height: auto;
    background-color: #445266;
    color: white
  }
  
  .inputsDiv {
    width: 100%;
    background-color: white;
    height: auto;
    display: flex;
    justify-content: center;
    border-radius: 0.45rem;
    border: solid 1px;
    border-color: rgb(212, 207, 207);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  
  .contentInputs {
    margin: 5px;
    background-color: white;
    width: 100%;
    height: 80px;
    border-radius: 5px;
  }
  
  .divTextHead {
    justify-content: center;
    height: 70px;
    display: flex;
  }
  
  .content {
    display: flex;
    justify-content: center;
  }
  
  .emailDiv {
    width: 70%;
    height: auto;
    margin-top: 40px;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: 0.3s;
  }
  
  .emailDiv:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }
  
  .divTextEmail {
    background-color: #1976d2;
    color: rgb(253, 253, 253);
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 0.45rem;
  }
  
  .graphicEmail {
    width: 100%;
    height: auto;
    background-color: rgb(254 253 253);
  }
  
  .subtitleDiv {
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: white;
  }
  
  .subtitle {
    font-size: small;
    width: 70%;
    color: white;
    background-color: rgb(131, 126, 126);
    margin-top: 20px;
    margin-bottom: 20px;
    opacity: 0.6;
  }
  
  .detailsGraphicDiv {
    display: flex;
    justify-content: space-around;
  }
  
  .colorNotReaded {
    width: 15px;
    height: 10px;
    background-color: red;
    margin-right: 5px;
  }
  
  .colorReaded {
    width: 15px;
    height: 10px;
    background-color: rgb(197 197 47);
    margin-right: 5px;
  }
  
  .detailNotReaded, .detailReaded {
    display: flex;
    align-items: center;
  }